import React from "react";
import {Card} from "react-bootstrap";

const DEFAULT_PLACEHOLDER_IMAGE =
  "http://imma.edu.gr/wp-content/uploads/2019/01/imma-logo-r-2.png";
  const fs = require('fs');

  const imageExists = (image_url) => {
    var http = new XMLHttpRequest();
    http.open('HEAD', image_url, false);
    http.send();
    return http.status != 404;
}

const Archive = ({ archive }) => {

  return (
    <Card style={{ width: '18rem' , marginBottom: '20px'}}>
      <Card.Img variant="top" src={imageExists(archive.file_name) ? archive.file_name : DEFAULT_PLACEHOLDER_IMAGE} />
      <Card.Body>
        <Card.Title>{archive.titlos}</Card.Title>
        <Card.Subtitle>{archive.gegonos}</Card.Subtitle>
        <Card.Text>
          {archive.analytiki_tekmiriosi}
        </Card.Text>        
      </Card.Body>
    </Card>
  )  
};


export default Archive;