import React, { useReducer, useEffect } from "react";
import "./App.css";
import Archive from "./components/Archive";
import Header from "./components/Header";
import Search from "./components/Search";
import { Container, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const domainName = window.location.host.substring(window.location.host.lastIndexOf(".", window.location.host.lastIndexOf(".") - 1) + 1);

const ARCHIVE_API_URL = `https://db.imma.edu.gr/api/image/read.php`;

const initialState = {
  loading: false,
  archives: [],
  errorMessage: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SEARCH_IMAGES_REQUEST":
      return {
        ...state,
        loading: true,
        errorMessage: null
      };
    case "SEARCH_IMAGES_SUCCESS":
      return {
        ...state,
        loading: false,
        archives: action.payload
      };
    case "SEARCH_IMAGES_FAILURE":
      return {
        ...state,
        loading: false,
        errorMessage: action.error
      };
    default:
      return state;
  }
};



const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {    
        fetch(`${ARCHIVE_API_URL}?&limit=50`)
            .then(response => response.json())
            .then(jsonResponse => {              
            dispatch({
                type: "SEARCH_IMAGES_SUCCESS",
                payload: jsonResponse.data
        	});
      	});
  	}, []);

    const search = searchValue => {
    	dispatch({
      	type: "SEARCH_IMAGES_REQUEST"
    	});
	
        fetch(`${ARCHIVE_API_URL}?&search=${searchValue}`)
      	.then(response => response.json())
      	.then(jsonResponse => {
        	if (jsonResponse.data) {
          	dispatch({
                type: "SEARCH_IMAGES_SUCCESS",
                payload: jsonResponse.data
          	});
        	} else {
          	dispatch({
                type: "SEARCH_IMAGES_FAILURE",
                error: jsonResponse.Error
          	});
          }
      	});
	  };

    const { archives, errorMessage, loading } = state;

    return (
    <div className="App">
      <Container>
        <Row style={{ marginBottom: '20px'}}>
          <Header text="ΜΟΥΣΕΙΟ ΜΑΚΕΔΟΝΙΚΟΥ ΑΓΩΝΑ" />          
        </Row>
        <Row style={{ marginBottom: '20px'}}>
          <h3 className="App-intro">Αρχειακό Υλικό</h3>
        </Row>
        <Row style={{ marginBottom: '20px'}}>
          <Search search={search} />
        </Row>
        <div className="movies">
        <Row style={{ marginBottom: '20px'}}>
          {loading && !errorMessage ? (  
            <Col sm><span>loading... </span></Col>               
          ) : errorMessage ? (
            <Col sm><div className="errorMessage">{errorMessage}</div></Col>        
          ) : (
            archives.map((archive, index) => {            
              return (
                <Col sm>
                  <Archive key={`${index}-${archive.media_name}`} archive={archive} />
                </Col>              
              );
            }            
            )
          )}
        </Row>
      </div>
      </Container>

      
    </div>
  );
};

export default App;