import React, { useState } from "react";
import {InputGroup, FormControl, Button, Alert} from "react-bootstrap";

const Search = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState("");
  
  const handleSearchInputChanges = (e) => {    
    setSearchValue(e.target.value);
  }

  const resetInputField = () => {
    setSearchValue("")
  }

  const callSearchFunction = (e) => {
    e.preventDefault();
    if (searchValue.length > 2){
        props.search(searchValue);
        resetInputField();
        setError('')
    }else{
        setError('Εισάγετε τουλάχιστον 3 χαρακτήρες.');
    }
    
  }
  return (
      <div>
        <InputGroup className="mb-3">    
        <FormControl
        placeholder="Εισάγετε λέξη - φράση αναζήτησης"
        aria-label="Εισάγετε λέξη - φράση αναζήτησης"
        aria-describedby="basic-addon2"
        onChange={handleSearchInputChanges}
        value={searchValue}
        />
        <Button variant="outline-secondary" id="button-addon2" onClick={callSearchFunction}>
            ΑΝΑΖΗΤΗΣΗ
        </Button>    
    </InputGroup>
        {error && <Alert variant="danger">{error}</Alert>}
    </div>  
  )
}

export default Search;